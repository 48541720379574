import React, { useEffect } from "react";

import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    withRouter,
} from "react-router-dom";
//custom components
import ServerApi from '../../services/server-api-service';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../header';
import Footer from '../footer';
import HomePage from '../home-page';
import WizardPage from '../wizard-page';
import ListingPage from '../listing-page';
import NeighborhoodPage from '../neighborhood-page';
import AboutPage from '../about-page';
import OurTeamPage from '../our-team-page';
import ContactUsPage from '../contact-us-page';
import SellPage from '../sell-page';
import PrivacyPage from '../privacy-page';
import SelectionPage from '../selection-page';
import SelectionListingPage from '../selection-listing-page';
import Page404 from '../page404';
import SearchPage from '../search-page';
import ConstructionLobbyPage from '../construction-lobby-page';
import NeighborhoodsPage from '../neighborhoods-page';
import ProjectCompanyPage from "../project-company-page";
import ProjectCompanyMobilePage from "../project-company-mobile-page";
import FindMyDealModal from '../find-my-deal-modal';
import HeaderSelection from '../header-selection';
import ConstructionLobbyMenu from '../construction-lobby-menu';
import ListingGallery from "../listing-gallery/listing-gallery";
import ArticleInnerPage from '../article-inner-page';
import ProjectInnerPage from '../project-inner-page';
import CustomLandingPage from "../custom-landing-page";
import ArticlesPage from '../articles-page';
import SelectionListingModal from '../selection-listing-modal';
import ShortRentalPage from '../short-rental-page';
import CookiesManager from '../../services/cookies-manager';
import Helmet from 'react-helmet';
import DocumentRedirectPage from "../document-redirect-page";
import StandAloneGalleryPage from "../stand-alone-gallery/stand-alone-gallery-page";
import LinkTreePage from "../link-tree-page/link-tree-page";
import SelectionProjectOfferPage from '../selection-project-offer-page';
// import Parser from 'html-react-parser';
import RouterMark from '../router-mark';

import ApplicationHistoryService from '../../services/application-history-service';
import ConfigurationService from "../../services/configuration-service";
import '../../elements/common.css';
import SelectionMap from "../selection-map/selection-map";
import Modal from "../modal";
import AgentForm from "../agent-form";
import SignAgreement from "../sign-agreement";

// import ListingPageModal from '../listing-page-modal';

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}

// function _GetLocation(props) {
//     const { pathname } = useLocation();
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, [pathname]);
//     return props.children
// }


const ScrollToTop = withRouter(_ScrollToTop)
class SmartLuxe extends React.Component {
    cookiesManager = null;
    applicationHistoryService = new ApplicationHistoryService();
    serverApi = new ServerApi();
    configurationService = new ConfigurationService();
    constructor(props) {
        super(props)
        this.state = {
            isSSR: true,
            dealIsVisible: false,
            flagClose: false,
            debug: false,
            hoveredMarkerId: null,
            mobileVerison: false,
            selectionListings: {},
            isVisibleCaruselModal: false,
            selectionListingModal: false,
            currentSlideSelection: '',
            currentProjectLogo: null,
            currentProjectLogoInvert: null,
            selectionDisplay: "flex",
            showSelectionMap: true,
            selectionHeader: false,
            showSelectionToolBar: false,
            mapSide: 'right',
            allowedLanguages: null,
            leftBlockWidth: 837,
            footerHideElem: false,
            isVisibleFooter: true,
            isVisibleHeader: true,
            selectionMapFloating: false,
            isEnablePopUps:true,
            currentSelectionListing: {},
            mapSecondaryMarkers: null,
            applicationHistory: [],
            heightRouterMark: 0,
            widthRouterMark: 'auto',
            articlesTranslations: null,
            headerDisableMenu:false,
            agent: null,
            currentPage: 'HomePage',
            routerMarkStyle:{},
            
            constructionLobbySearchForm: {
                neighbourhood: '',
                condo: false,
                singleFamilyHome: false,
                townhouse: false,
                shortTermApproved: false
            }
        }
        this.closeModal = this.closeModal.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    getDictionary() {
        this.serverApi.getDictionary()
            .then((data) => {
                if (this.state.debug) {
                    console.log('getDictionary data: ', data);
                }
                this.setState({
                    dictionary: data
                });

            })
            .catch((error) => {
                console.log(`ERROR: fetch getFeaturedNeighboorhoods ... -> Component: APP  ERROR:${error}`);
            })
    }


    getGeoInfo() {
        this.serverApi.getGeoIp()
            .then((data) => {
                if (this.state.debug) {
                    console.log('getGeoIp data:', data);
                }

                this.setState((prevState) => {
                    return {
                        country: data.iso_code
                    }
                })

            })
    }

    getNeighborhoods() {
        this.serverApi.getFeaturedNeighborhoods()
            .then((data) => {
                this.setState({
                    neighboorhoods: data
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getFeaturedNeighboorhoods ... -> Component: APP  ERROR:${error}`);
            })
    }



    componentWillMount() {
        console.log('App mounted')
        this.configurationService.checkDomainName();
        // this.configurationService.checkLanguage(this.state.currentPage);
        this.getDictionary();
        this.getNeighborhoods();
        this.getGeoInfo();

    }

    componentDidMount() {
        this.getVersion()

        this.setState({
            isSSR: false
        })

        this.configurationService.checkDomainName();
        //get dictionary    
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
        //  window.addEventListener("orientationchange", this.handleMobileOrientation, false);
        document.addEventListener('markerHover', this.handleMarkerHover);
        document.addEventListener('openModal', this.handleOpenModal);
        window.addEventListener('popstate', this.handleBackButton);

        if (window.innerWidth <= 775) {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
                mobileVerison: true,
            })
        } else {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        this.cookiesManager = new CookiesManager();
        if (this.state.debug) {
            console.log("componentDidMount ... -> Component: App");
        }

        sessionStorage.setItem('referrer', document.referrer)
        let search = document.location.search;
        let params = {}
        if (    document.location.search.includes('gclid=') || document.location.search.includes('fbclid=') ||
                document.location.search.includes('wbraid=') || document.location.search.includes('utm_source=') ||
                document.location.search.includes('tbclid=') ) {
            const hashes = search.slice(search.indexOf('?') + 1).split('&')

            hashes.map(hash => {
                const [key, val] = hash.split('=')
                params[key] = decodeURIComponent(val)
                return null;
            })
        }
        if (params.gclid !== null) sessionStorage.setItem('GCLID', params.gclid)
        if (params.fbclid !== null) sessionStorage.setItem('FBCLID', params.fbclid)
        if (params.wbraid !== null) sessionStorage.setItem('WBRAID', params.wbraid)
        if (params.tbclid !== null) sessionStorage.setItem('TBCLID', params.tbclid)
        if (params.utm_source !== null) sessionStorage.setItem('referrer', params.utm_source+'.com')
        if (this.cookiesManager.get('exit_pop') === undefined || this.cookiesManager.get('exit_pop') !== '1') {
            setTimeout(() => {
                this.setState({
                    flagClose: true//true - open when try close
                })
            }, 6000)
        }
        // if(sessionStorage.getItem('flagExitModal')===null||sessionStorage.getItem('flagExitModal') !== 'false'){
        //     setTimeout(() => {
        //         this.setState({
        //             flagClose: true//true - open when try close
        //         })
        //     }, 100)
        // }
    }

    componentWillUpdate(prevProps,prevState) {
        if(this.state.currentPage !== prevState.currentPage){
            this.configurationService.checkLanguage(prevState.currentPage);
        }

        if (this.state.version && document.getElementById('version') && this.state.version != document.getElementById('version').content) {
            window.location.href = window.location.href
        }
        if ((document.location.pathname.includes('/selection') || document.location.pathname.includes('/projects'))
            && !(document.location.pathname.includes('/projects') && (document.location.pathname.includes('/article')))) {
            // scrollbar.overflow='none';
            document.body.classList.add('hidden-scrollbar');
        } else {
            document.body.classList.remove('hidden-scrollbar');
        }
        this.insertJSONLD();

        if (this.props.location !== prevProps.location) {
            this.applicationHistoryService.push(this.props.location);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('markerHover', this.handleMarkerHover);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('popstate', this.handleBackButton);
        document.removeEventListener('openModal', this.handleOpenModal);

    }

    getVersion = () => {
        this.serverApi.getVersion()
            .then((data) => {
                this.setState({
                    version: data.version
                })
            })
            .catch((error) => {
                console.log('Error with get verison: ', error)
            })
    }

    insertJSONLD() {
        let { dictionary } = this.state;
        //insert JSON-LD

        if (dictionary !== undefined && !document.location.pathname.includes('/listing')) {
            const metaData = `{
            "@context": "https://schema.org/",
            "@type": "Thing",
            "name": "Smart Luxe - ${dictionary.slogan}",
            "subjectOf": {
                "@type": "Site",
                "name": "Smart Luxe - ${dictionary.slogan}"
            }
        }`
            if (document.getElementById('productApplactionLd') !== null && document.getElementById('productApplactionLd').textContent !== metaData) {
                document.getElementById('productApplactionLd').remove();
            }


            const jsonldScript = document.createElement('script');
            jsonldScript.setAttribute('type', 'application/ld+json');
            jsonldScript.setAttribute('id', 'productApplactionLd');


            if (document.getElementById('productApplactionLd') === null) {
                jsonldScript.textContent = metaData;
                document.head.appendChild(jsonldScript);
            }
        }
    }

    handleBackButton = (event) => {
        if (this.applicationHistoryService.getLength() > 0 && event.state != null) {
            this.applicationHistoryService.pop();
            // this.applicationHistoryService.removeByKey(event.state.key)
        }
    }

    handleMarkerHover = (event) => {
        this.setState({
            hoveredMarkerId: event.detail.id
        })
    }

    handleMouseMove = (event) => {
        this.setState(() => {
            return {
                mouseEvent: event
            }
        })
        if (!this.state.isSSR) {
            if (event.clientY < 5 && this.state.flagClose && (this.cookiesManager.get('exit_pop') === undefined || this.cookiesManager.get('exit_pop') !== '1') && this.state.isEnablePopUps) {
                this.setState({
                    dealIsVisible: true
                })
            }
        }

    }

    handleOpenModal = (event) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                [event.detail.name]: event.detail.value,
                currentSlideSelection: event.detail.currentSlide
            }
        })
    }

    handleResize(event) {
        // console.log(visualViewport);
        // let width = event.target.innerWidth;
        // let height = event.target.innerHeight;

        let width = visualViewport.width;
        let height = visualViewport.height;
        let mobileVerison = this.state.mobileVerison;

        if (width <= 775 && !mobileVerison) {
            if (this.state.debug) {
                console.log('Swithced to mobile version');

            }
            this.setState({
                width: width,

                height: height,
                mobileVerison: true
            })
        } else if (width > 775 && mobileVerison) {
            if (this.state.debug) {
                console.log('Swithced to desktop version');

            }
            this.setState({
                width: width,
                height: height,
                mobileVerison: false
            })
        } else {
            this.setState({
                height: height,
                width: width
            })
        }
    }

    handleScroll() {
        this.setState({
            pageScroll: window.pageYOffset
        })
    }

    handleInputForm = (formName, name, value) => {
        this.setState({
            ...this.state,
            [formName]: {
                ...this.state[formName],
                [name]: value
            }
        }, () => {
            if (formName === 'constructionLobbySearchForm') {
                window.dispatchEvent(new CustomEvent('getProjectsList', {}))
            }
        })
    }

    closeModal = () => {
        if (!this.state.isSSR) {
            if (this.cookiesManager.get('exit_pop') == null) {
                this.cookiesManager.set('exit_pop', 1, 30)
            }
            this.setState({
                dealIsVisible: false,
                flagClose: false,
            })
        }
    }

    saveSelectionListings = (data, selectionId, agentId = null) => {
        this.setState({
            dataForMap: data,
            selectionId: selectionId,
            agentId: agentId
        })
        if (!this.state.isSSR) {
            if (this.cookiesManager.get(selectionId)) {
                let listingExpiredList = JSON.parse(this.cookiesManager.get(selectionId))
                if (listingExpiredList) {
                    this.setState({
                        listingExpiredList: listingExpiredList
                    })
                }
            }
        }
    }

    saveCurrentSelectionListing = (data) => {
        this.setState({
            currentSelectionListing: data
        })
    }

    setSliderModal = (value) => {
        this.setState({
            isVisibleSliderModal: value
        })
    }

    saveDataForMap = (data, pageInfo) => {
        this.setState({
            dataForMap: data
        })
    }

    saveState = (obj) => {
        if (obj) {
            this.setState((prevSate) => {
                return obj;
            })
        }
    }



    // saveState = (stateName, value) => {
    //     this.setState((prevSate) => {
    //         return {
    //             [stateName]: value
    //         }
    //     })
    // }

    renderBody() {
        if (this.state.dictionary !== undefined && this.state.neighboorhoods) {
            let { currentSlideSelection, selectionListingModal, isVisibleSliderModal,
                agentId, dictionary, dataForMap, neighboorhoods,
                mobileVerison, width, height,
                currentSelectionListing, selectionId, listingExpiredList, hoveredMarkerId, country,
                currentProjectLogo, isVisibleFooter, selectionMapFloating, allowedLanguages, mapSecondaryMarkers,
                constructionLobbySearchForm, selectionDisplay, showSelectionToolBar, mapSide, selectionHeader, leftBlockWidth,
                mapSoldOutMarkers, currentProjectLogoInvert, footerHideElem, currentPage, agent, pageScroll, mouseEvent, articlesTranslations,
                isVisibleHeader,headerDisableMenu } = this.state;
            let { location, history } = this.props;

            const pageTransition = {
                in: {
                    opacity: 1
                },
                out: {
                    opacity: 0
                }
            }
            if (!document.location.pathname.includes('/selection') && (!document.location.pathname.includes('/project'))
                && !document.location.pathname.includes('/articles')) {

                return (

                    <div onMouseMove={this.handleMouseMove.bind(this)} id="smartluxe">
                        {/* META TAGS */}
                        {
                            <Helmet>
                                <title>Smart Luxe - {dictionary.slogan}</title>
                                <meta name="description" content={dictionary.deal_finder_desc} />
                            </Helmet>
                        }

                        {/* META TAGS */}
                        {isVisibleHeader && <Header
                            width={width}
                            saveState={this.saveState}
                            neighboorhoods={neighboorhoods}
                            dictionary={this.state.dictionary}
                            pageScroll={this.state.pageScroll}
                            allowedLanguages={allowedLanguages}
                            articlesTranslations={articlesTranslations}
                            disableMenu={headerDisableMenu}
                        />}

                        <Switch location={location} key={location.pathname}>
                            <Route exact path="/">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                        style={{ minHeight: '2000px' }}
                                    >
                                        <HomePage
                                            neighboorhoods={neighboorhoods}
                                            mobileVerison={mobileVerison}
                                            width={width}
                                            dictionary={this.state.dictionary}
                                            saveState={this.saveState}
                                            hoveredMarkerId={hoveredMarkerId}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            <Route path={["/listing/:id/gallery"]}>
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <ListingGallery
                                            width={width}
                                            dictionary={this.state.dictionary}
                                            height={height}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>
                            <Route path={["/find-my-deal", "/neighborhood/:id/:name/find-my-deal"]}>
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <WizardPage
                                            dictionary={this.state.dictionary}
                                            width={this.state.width}
                                            mobileVerison={mobileVerison}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>
                            <Route path={["/listing/:id/:agentId","/listing/:id"]}>
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <ListingPage
                                            width={width}
                                            dictionary={this.state.dictionary}
                                            height={height}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                           

                            <Route path={["/neighborhood/:id/:name", "/neighborhood/:id"]}>
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <NeighborhoodPage
                                            dictionary={this.state.dictionary}
                                            width={width}
                                            country={country}
                                            saveState={this.saveState}
                                            hoveredMarkerId={hoveredMarkerId}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>
                            <Route path="/neighborhoods">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <NeighborhoodsPage
                                            dictionary={this.state.dictionary}
                                            width={width}
                                            neighboorhoods={neighboorhoods}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>
                            <Route path="/about">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <AboutPage
                                            dictionary={this.state.dictionary}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>
                            <Route path="/team">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <OurTeamPage
                                            dictionary={this.state.dictionary}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>
                            <Route path="/contact">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <ContactUsPage
                                            dictionary={this.state.dictionary}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            <Route path="/sell">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <SellPage
                                            dictionary={this.state.dictionary}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            <Route path="/privacy">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <PrivacyPage
                                            dictionary={this.state.dictionary}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            <Route path="/l/:instagram">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <LinkTreePage
                                            dictionary={this.state.dictionary}
                                            country={country}
                                            saveState={this.saveState}
                                            width={width}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            <Route path="/page-not-found">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <Page404
                                            dictionary={this.state.dictionary}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>



                            <Route path="/Search">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <SearchPage
                                            dictionary={this.state.dictionary}
                                            width={this.state.width}
                                            country={country}
                                            hoveredMarkerId={hoveredMarkerId}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            <Route path="/short_term_rental">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <ShortRentalPage
                                            dictionary={this.state.dictionary}
                                            width={this.state.width}
                                            country={country}
                                            hoveredMarkerId={hoveredMarkerId}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>
                            <Route path="/campaigns/:name">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <CustomLandingPage
                                            dictionary={this.state.dictionary}
                                            width={this.state.width}
                                            country={country}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            <Route path={"/agreement/sign/:agreementId"}>
                                        <ScrollToTop>
                                            <motion.div
                                                className="PageContent"
                                                variants={pageTransition}
                                                exit="out"
                                                animate="in"
                                                initial="out"
                                            >
                                                <SignAgreement
                                                    pageScroll={pageScroll}
                                                    mobileVerison={mobileVerison}
                                                    dictionary={this.state.dictionary}
                                                    width={this.state.width}
                                                    country={country}
                                                    saveState={this.saveState}
                                                />
                                            </motion.div>
                                        </ScrollToTop>
                                    </Route>

                            <Route path="/doc/:id">
                                <ScrollToTop>
                                    <motion.div
                                        className="PageContent"
                                        variants={pageTransition}
                                        exit="out"
                                        animate="in"
                                        initial="out"
                                    >
                                        <DocumentRedirectPage
                                            dictionary={this.state.dictionary}
                                            width={this.state.width}
                                            saveState={this.saveState}
                                        />
                                    </motion.div>
                                </ScrollToTop>
                            </Route>

                            {/* <Redirect to="/page-not-found"></Redirect> */}
                        </Switch>
                        {isVisibleFooter && <Footer
                            dictionary={this.state.dictionary}
                            neighboorhoods={neighboorhoods}
                            hideElems={footerHideElem}
                        />}

                        <Modal isVisible={0}>
                            <AgentForm>

                            </AgentForm>
                        </Modal>

                        {/*<a href="http://smartluxe.test:3000/"> Click me</a>*/}
                        <FindMyDealModal
                            isVisible={this.state.dealIsVisible} //this.state.dealIsVisible
                            country={country}
                            //isVisible={true}
                            closeModal={this.closeModal}
                            dictionary={this.state.dictionary}
                            location={location}
                        />
                    </div>
                )
            } else if (document.location.pathname.includes('project/') || document.location.pathname.includes('articles')) {
                //selection section 
                // let { showSelectionMap, selectionHeader, mapSide, showSelectionToolBar,
                //     currentProjectLogoInvert, selectionDisplay, footerHideElem,
                //     leftBlockWidth, mapSoldOutMarkers,pageScroll } = this.state;


                let itemsAling = 'center';
                if (selectionMapFloating) {
                    itemsAling = 'flex-end'
                }

                let selectionStyle = {
                    display: selectionDisplay,
                    flexDirection: 'row',
                    justifyContent: itemsAling
                }

                if (mapSide != "left") {
                    selectionStyle = {
                        flexDirection: 'row-reverse',
                        display: selectionDisplay,
                        justifyContent: itemsAling,
                    }
                }
                //mapSide == "left" ? ({ flexDirection: 'row', justifyContent: itemsAling }) : ({ flexDirection: 'row-reverse', justifyContent: itemsAling })

                let flagLeftMap = false; // also it's mean that page projects (for markers) 
                let showHeader = document.location.pathname.includes('project');
                if (document.location.pathname.includes('/campaign')) showHeader = false;
                if (document.location.pathname.includes('project') && !document.location.pathname.includes('/article') && document.location.pathname.includes('/cаmpaign')) flagLeftMap = false;
                return (
                    <div onMouseMove={this.handleMouseMove.bind(this)} id="smartluxe" >
                        {!selectionHeader ? (
                            <Header
                            width={width}
                                saveState={this.saveState}
                                neighboorhoods={neighboorhoods}
                                dictionary={this.state.dictionary}
                                pageScroll={this.state.pageScroll}
                                articlesTranslations={articlesTranslations}
                                allowedLanguages={allowedLanguages}
                                disableMenu={headerDisableMenu}
                            />
                        ) : (
                            <HeaderSelection
                                saveState={this.saveState}
                                width={width}
                                dictionary={dictionary}
                                selectionId={selectionId}
                                currentSelectionListing={currentSelectionListing}
                                agentId={agentId}
                                currentProjectLogo={currentProjectLogo}
                                allowedLanguages={allowedLanguages}
                            />
                        )}



                        {!showSelectionToolBar ? (null) : (
                            <ConstructionLobbyMenu
                                applicationHistoryService={this.applicationHistoryService}
                                neighboorhoods={neighboorhoods}
                                projectPage={showHeader}
                                dictionary={dictionary}
                                width={this.state.width}
                                currentSelectionListing={currentSelectionListing}
                                form={constructionLobbySearchForm}
                                handleInputForm={this.handleInputForm}
                            />
                        )}
                        <div className='Selection' style={selectionStyle}>
                            {!document.location.pathname.includes('/article') && !document.location.pathname.includes('/campaign') && !isVisibleSliderModal ? (
                                <SelectionMap
                                    height={height}
                                    dictionary={dictionary}
                                    leftBlockWidth={leftBlockWidth}
                                    width={this.state.width}
                                    listings={dataForMap}
                                    currentSelectionListing={currentSelectionListing}
                                    selectionId={selectionId}
                                    agentId={agentId}
                                    listingExpiredList={listingExpiredList}
                                    markersWithModal={!flagLeftMap}
                                    hoveredMarkerId={hoveredMarkerId}
                                    selectionMapFloating={selectionMapFloating}
                                    saveCurrentSelectionListing={this.saveCurrentSelectionListing}
                                    mapSecondaryMarkers={mapSecondaryMarkers}
                                    mapSoldOutMarkers={mapSoldOutMarkers}
                                    currentPage={currentPage}
                                />
                            ) : null}
                            <Switch location={location} key={location.pathname}>
                                <Route path={["/project/:id/campaign/:name"]}>
                                    <ScrollToTop>
                                        <motion.div
                                            className="PageContent"
                                            variants={pageTransition}
                                            exit="out"
                                            animate="in"
                                            initial="out"
                                        >
                                            <ProjectCompanyPage
                                                mouseEvent={mouseEvent}
                                                dictionary={this.state.dictionary}
                                                width={this.state.width}
                                                country={country}
                                                saveState={this.saveState}
                                                pageScroll={this.state.pageScroll}
                                                mobileVerison={mobileVerison}
                                                height={height}
                                            />
                                        </motion.div>
                                    </ScrollToTop>
                                </Route>

                                <Route path="/project/:id/mobile-campaign/:name">
                                    <ScrollToTop>
                                        <motion.div
                                            className="PageContent"
                                            variants={pageTransition}
                                            exit="out"
                                            animate="in"
                                            initial="out"
                                        >
                                            <ProjectCompanyMobilePage
                                                dictionary={this.state.dictionary}
                                                width={this.state.width}
                                                country={country}
                                                saveState={this.saveState}
                                                height={height}
                                            />
                                        </motion.div>
                                    </ScrollToTop>
                                </Route>

                                <Route path={["/projects/:projectId/:projectName/articles/:id/:articleName", "/articles/:id/:articleName"]}>
                                    <ScrollToTop>
                                        <motion.div
                                            className="PageContent"
                                            variants={pageTransition}
                                            exit="out"
                                            animate="in"
                                            initial="out"
                                        >
                                            <ArticleInnerPage
                                                pageScroll={pageScroll}
                                                mobileVerison={mobileVerison}
                                                neighborhoods={neighboorhoods}
                                                dictionary={this.state.dictionary}
                                                width={this.state.width}
                                                country={country}
                                                saveState={this.saveState}
                                            />
                                        </motion.div>
                                    </ScrollToTop>
                                </Route>




                                <Route path={"/articles"}>
                                    <ScrollToTop>
                                        <motion.div
                                            className="PageContent"
                                            variants={pageTransition}
                                            exit="out"
                                            animate="in"
                                            initial="out"
                                        >
                                            <ArticlesPage
                                                neighborhoods={neighboorhoods}
                                                dictionary={this.state.dictionary}
                                                width={this.state.width}
                                                country={country}
                                                saveState={this.saveState}
                                            />
                                        </motion.div>
                                    </ScrollToTop>
                                </Route>

                            </Switch>



                        </div>
                        {isVisibleFooter && <Footer
                            dictionary={this.state.dictionary}
                            neighboorhoods={neighboorhoods}
                            currentProjectLogoInvert={currentProjectLogoInvert}
                            hideElems={footerHideElem}
                        />}

                    </div>

                )
            } else if (document.location.pathname.includes('projects') || document.location.pathname.includes('selection/')) {
                let { selectionHeader, mapSide, showSelectionToolBar, isVisibleHeader,
                    currentProjectLogoInvert, selectionDisplay, footerHideElem,
                    leftBlockWidth, mapSoldOutMarkers, widthRouterMark, pageScroll, heightRouterMark, currentPage, width,routerMarkStyle,headerDisableMenu} = this.state;

                let itemsAling = 'center';
                if (selectionMapFloating) {
                    itemsAling = 'flex-end'
                }

                let selectionStyle = {
                    display: selectionDisplay,
                    flexDirection: 'row',
                    justifyContent: itemsAling
                }

                if (mapSide != "left") {
                    selectionStyle = {
                        flexDirection: 'row-reverse',
                        display: selectionDisplay,
                        justifyContent: itemsAling,
                        paddingTop: !showSelectionToolBar && isVisibleHeader ? "130px" : '' //for disable LobbyMenuLine
                    }
                    // if(showSelectionToolBar) selectionStyle.paddingTop = '130px'
                    if (width < 1000) {
                        selectionStyle = {
                            flexDirection: 'row-reverse',
                            display: selectionDisplay,
                            justifyContent: itemsAling,
                            paddingTop: !showSelectionToolBar && isVisibleHeader ? "84px" : ''
                            // paddingTop: "84px" //for disable LobbyMenuLine
                        }
                    }

                }
                //mapSide == "left" ? ({ flexDirection: 'row', justifyContent: itemsAling }) : ({ flexDirection: 'row-reverse', justifyContent: itemsAling })

                let flagLeftMap = false; // also it's mean that page projects (for markers) 
                let showHeader = document.location.pathname.includes('project');
                return (
                    <div onMouseMove={this.handleMouseMove.bind(this)} id="smartluxe" >
                        {
                            isVisibleHeader ? (!selectionHeader ? (
                                <Header
                                width={width}
                                    saveState={this.saveState}
                                    neighboorhoods={neighboorhoods}
                                    dictionary={this.state.dictionary}
                                    pageScroll={this.state.pageScroll}
                                    allowedLanguages={allowedLanguages}
                                    articlesTranslations={articlesTranslations}
                                    disableMenu={headerDisableMenu}
                                />
                            ) : (
                                <HeaderSelection
                                    saveState={this.saveState}
                                    width={width}
                                    dictionary={dictionary}
                                    selectionId={selectionId}
                                    currentSelectionListing={currentSelectionListing}
                                    agentId={agentId}
                                    currentProjectLogo={currentProjectLogo}
                                    allowedLanguages={allowedLanguages}
                                />
                            )
                            ) : (
                                null
                            )
                        }



                        {!showSelectionToolBar ? (null) : (
                            <ConstructionLobbyMenu
                                applicationHistoryService={this.applicationHistoryService}
                                neighboorhoods={neighboorhoods}
                                projectPage={showHeader}
                                dictionary={dictionary}
                                width={this.state.width}
                                currentSelectionListing={currentSelectionListing}
                                form={constructionLobbySearchForm}
                                handleInputForm={this.handleInputForm}
                            />
                        )}
                        <div className='Selection' style={selectionStyle}>
                            {!document.location.pathname.includes('/article') && !document.location.pathname.includes('/campaign') && !isVisibleSliderModal ? (
                                <SelectionMap
                                    height={height}
                                    dictionary={dictionary}
                                    leftBlockWidth={leftBlockWidth}
                                    width={this.state.width}
                                    listings={dataForMap}
                                    currentSelectionListing={currentSelectionListing}
                                    selectionId={selectionId}
                                    agentId={agentId}
                                    listingExpiredList={listingExpiredList}
                                    markersWithModal={!flagLeftMap}
                                    hoveredMarkerId={hoveredMarkerId}
                                    selectionMapFloating={selectionMapFloating}
                                    saveCurrentSelectionListing={this.saveCurrentSelectionListing}
                                    mapSecondaryMarkers={mapSecondaryMarkers}
                                    mapSoldOutMarkers={mapSoldOutMarkers}
                                    currentPage={currentPage}
                                />
                            ) : null}
                            <RouterMark 
                                style={routerMarkStyle}
                                width={widthRouterMark}
                                minHeight={heightRouterMark}
                                applicationWidth={width}
                            >
                                <Switch location={location} key={location.pathname}>

                                    <Route path={"/projects/:projectId/:projectName/gallery"}>
                                        <ScrollToTop>
                                            <motion.div
                                                className="PageContent"
                                                variants={pageTransition}
                                                exit="out"
                                                animate="in"
                                                initial="out"
                                            >
                                                <StandAloneGalleryPage
                                                    pageScroll={pageScroll}
                                                    mobileVerison={mobileVerison}
                                                    neighborhoods={neighboorhoods}
                                                    dictionary={this.state.dictionary}
                                                    width={this.state.width}
                                                    country={country}
                                                    saveState={this.saveState}
                                                />
                                            </motion.div>
                                        </ScrollToTop>
                                    </Route>

                                    

                                    <Route path="/projects/:id/:name">
                                        <ScrollToTop>
                                            <motion.div
                                                className="PageContent"
                                                variants={pageTransition}
                                                exit="out"
                                                animate="in"
                                                initial="out"
                                            >
                                                <ProjectInnerPage
                                                    mouseEvent={mouseEvent}
                                                    mobileVerison={mobileVerison}
                                                    pageScroll={pageScroll}
                                                    widthRouterMark={widthRouterMark}
                                                    hoveredMarkerId={hoveredMarkerId}
                                                    dictionary={this.state.dictionary}
                                                    width={this.state.width}
                                                    saveCurrentSelectionListing={this.saveCurrentSelectionListing}
                                                    saveDataForMap={this.saveDataForMap}
                                                    country={country}
                                                    neighbourhoods={neighboorhoods}
                                                    saveState={this.saveState}
                                                />
                                            </motion.div>
                                        </ScrollToTop>
                                    </Route>



                                    <Route path={["/projects/:id", "/projects"]}>
                                        <ScrollToTop>
                                            <motion.div
                                                className="PageContent"
                                                variants={pageTransition}
                                                exit="out"
                                                animate="in"
                                                initial="out"
                                            >
                                                <ConstructionLobbyPage
                                                    widthRouterMark={widthRouterMark}
                                                    form={constructionLobbySearchForm}
                                                    hoveredMarkerId={hoveredMarkerId}
                                                    dictionary={this.state.dictionary}
                                                    width={this.state.width}
                                                    saveDataForMap={this.saveDataForMap}
                                                    saveCurrentSelectionListing={this.saveCurrentSelectionListing}
                                                    country={country}
                                                    saveState={this.saveState}
                                                />
                                            </motion.div>
                                        </ScrollToTop>
                                    </Route>

                                    <Route path={["/selection/:selectionId/:agentId/listing/:listingId", "/selection/:selectionId/listing/:listingId"]}>
                                        <ScrollToTop>

                                            <motion.div
                                                variants={pageTransition}
                                                exit="out"
                                                animate="in"
                                                initial="out"
                                                className={"SelectionRT"}
                                            >
                                                <SelectionListingPage
                                                    widthRouterMark={widthRouterMark}
                                                    dictionary={this.state.dictionary}
                                                    width={this.state.width}
                                                    currentSelectionListing={currentSelectionListing}
                                                    saveCurrentSelectionListing={this.saveCurrentSelectionListing}
                                                    saveSelectionListings={this.saveSelectionListings}
                                                    height={height}
                                                    country={country}
                                                    agentId={agentId}
                                                    setSliderModal={this.setSliderModal}
                                                    saveState={this.saveState}
                                                />
                                            </motion.div>
                                        </ScrollToTop>
                                    </Route>

                                    <Route path={["/selection/:selectionId/:agentId/offer/:offerId", "/selection/:selectionId/offer/:offerId"]}>
                                        <ScrollToTop>

                                            <motion.div
                                                variants={pageTransition}
                                                exit="out"
                                                animate="in"
                                                initial="out"
                                                className={"SelectionRT"}
                                            >
                                                <SelectionProjectOfferPage
                                                    widthRouterMark={widthRouterMark}
                                                    dictionary={this.state.dictionary}
                                                    width={this.state.width}
                                                    height={height}
                                                    agentId={agentId}
                                                    saveState={this.saveState}
                                                    saveSelectionListings={this.saveSelectionListings}
                                                    saveDataForMap={this.saveDataForMap}
                                                />
                                            </motion.div>
                                        </ScrollToTop>
                                    </Route>

                                    <Route path={["/selection/:selectionId/:agentId", "/selection/:selectionId"]}>
                                        <ScrollToTop>
                                            <motion.div

                                                variants={pageTransition}
                                                exit="out"
                                                animate="in"
                                                initial="out"
                                                className="SelectionRT"
                                            >
                                                <SelectionPage
                                                    hoveredMarkerId={hoveredMarkerId}
                                                    widthRouterMark={widthRouterMark}
                                                    dictionary={this.state.dictionary}
                                                    width={width}
                                                    agentId={agentId}
                                                    saveCurrentSelectionListing={this.saveCurrentSelectionListing}
                                                    saveSelectionListings={this.saveSelectionListings}
                                                    country={country}
                                                    saveState={this.saveState}
                                                />
                                            </motion.div>

                                        </ScrollToTop>
                                    </Route>


                                    

                                </Switch>
                                <SelectionListingModal
                                    dictionary={dictionary}
                                    width={width}
                                    height={height}
                                    isVisible={selectionListingModal}
                                    data={currentSelectionListing}
                                    country={country}
                                    currentSlide={currentSlideSelection}
                                    agent={agent}
                                />
                            </RouterMark>
                        </div>
                        {isVisibleFooter && <Footer
                            dictionary={this.state.dictionary}
                            neighboorhoods={neighboorhoods}
                            currentProjectLogoInvert={currentProjectLogoInvert}
                            hideElems={footerHideElem}
                        />}

                    </div>

                )
            }
        } else {
            return null;
        }
    }

    render() {
        this.insertJSONLD();
        return (
            <>
                <AnimatePresence >

                    {this.renderBody()}
                </AnimatePresence>

            </>
        )
    }
}
export default withRouter(SmartLuxe)