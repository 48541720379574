import ConfigurationService from './configuration-service'

const configurationService = new ConfigurationService();
// let siteUrl =configurationService.getCurrentSite();
let lang = configurationService.getCurrentLanguagePrefix();
let langForSend = 'English';

switch (lang) {
    case 'pt': {
        langForSend = "Portuguese"
        break;
    }
    case 'es': {
        langForSend = "Spanish"
        break;
    }
    case 'tr': {
        langForSend = "Turkish"
        break;
    }
}
// let siteUrl = servers[config.currentServer].serverApi;
// console.log(config.currentServer);
// if(config.currentServer!="onserv"){
//   siteUrl = window.location.protocol+"//"+window.location.hostname+"/";
// }
const debug = true;
//const siteUrl = 'http://www.labmedica.com.onserv/';з
//const siteUrl = 'https://www.labmedica.com.axis1.net/';



export default class ServerApi {

    //_apiBase = 'https://www.hospimedica.com/index.php?option=com_mobile_app&task=';
    // _apiBase = '/index2.php?option=login&ajax=';
    _hostName = 'https://ngirealty.com/';
    //_hostName = 'http://ngi.test/';
    _api = 'api/'
    _siteName = "smart-luxe/"
    _ver = 'v1/'
    _lang = `${lang}/`

    constructor() {
        this._hostName= configurationService.getDomain();
    }

    // _lang = `&lang=${lang}`;

    //   async getData(task_name) {

    //     if (debug) {
    //       console.log('Featching url - >> ', `${siteUrl}${this._apiBase}${this._optionBase}${task_name}${this._lang}`);
    //     }

    //     const res = await fetch(`${siteUrl}${this._apiBase}${this._optionBase}&task=${task_name}${this._lang}`, {
    //       headers: {
    //         'Content-type': 'application/x-www-form-urlencoded',
    //         'Access-Control-Allow-Credentials': '*'
    //       },
    //       credentials: 'include',
    //     });

    //     if (!res.ok) {
    //       throw new Error(`Could not fetch ${siteUrl}${this._apiBase}${task_name}${this._lang}` +
    //         `, received ${res.status}`)
    //     }
    //     return await res.json();
    //   }

    //   async sendData(task_name, objectWithData) {

    //     if (this.debug) {
    //       console.log('JSON to send: ');
    //       console.log(JSON.stringify(objectWithData));
    //       alert(`${siteUrl}${this._apiBase}${this._optionBase}&task=${task_name}${this._lang}`);
    //     }
    //     let res;
    //     res = await fetch(`${siteUrl}${this._apiBase}${this._optionBase}&task=${task_name}${this._lang}`, {
    //       method: 'POST',
    //       headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Credentials': '*'
    //       },
    //       body: JSON.stringify(objectWithData),
    //     });



    //     if (!res.ok) {
    //       throw new Error(`Could not fetch ${siteUrl}${this._apiBase}${this._optionBase}$task=${task_name}${this._lang}` +
    //         `, received ${res.status}`)
    //     }
    //     return await res.json();
    //   }

    async convertToSendForm(form) {
        // let geoData = await this.getGeoData();
        let comments = '', project = 'General Interest', area = '', usage = '', language = langForSend, address = '', message = '', price_range = null, bedrooms = 0, bathrooms = 0, timeline = 0;
        let referrer = sessionStorage.getItem('referrer')
        let gclid = sessionStorage.getItem('GCLID')
        let fbclid = sessionStorage.getItem('FBCLID')
        let wbraid = sessionStorage.getItem('WBRAID')
        let tbclid = sessionStorage.getItem('TBCLID')
        if (form.hasOwnProperty('preferPrice')) {
            if (form.contactInfo.hasOwnProperty('userComment')) comments = form.contactInfo.userComment;
            if (form.contactInfo.hasOwnProperty('project')) project = form.contactInfo.project;
            if (form.hasOwnProperty('userMessage')) message = form.userMessage;
            if (form.hasOwnProperty('preferPrice')) price_range = form.preferPrice;
            if (form.hasOwnProperty('preferUsage')) usage = form.preferUsage;
            if (form.hasOwnProperty('preferBedrooms')) bedrooms = form.preferBedrooms;
            if (form.hasOwnProperty('preferBathrooms')) bathrooms = form.preferBathrooms;
            if (form.hasOwnProperty('preferTimeline')) timeline = form.preferTimeline;
            if (form.hasOwnProperty('area')) area = form.area;
            let newForm = {
                name: form.contactInfo.userFullName,
                phone: form.contactInfo.userPhone,
                email: form.contactInfo.userEmail,
                sample_deals: form.contactInfo.sample_deals,
                comments: comments,
                default_comments: form.isDefaultText,
                price_range: price_range,
                bedrooms: bedrooms,
                bathrooms: bathrooms,
                usage: usage,
                timeline: timeline,
                area: area,
                // country:geoData.country,
                // state:geoData.state,
                // city:geoData.city,
                language: language,
                message: message,
                project: project,
                referrer: referrer,
                GCLID: gclid,
                FBCLID: fbclid,
                WBRAID: wbraid,
                TBCLID: tbclid
            }
            return newForm;
        } else {
            if (form.hasOwnProperty('userComment')) comments = form.userComment;
            if (form.hasOwnProperty('project')) project = form.project;
            if (form.hasOwnProperty('userMessage')) message = form.userMessage;
            if (form.hasOwnProperty('userAddress')) address = form.userAddress;
            if (form.hasOwnProperty('price_range')) price_range = form.price_range;
            let newForm = {
                name: form.userFullName,
                phone: form.userPhone,
                email: form.userEmail,
                comments: comments,
                address: address,
                price_range: price_range,
                default_comments: form.isDefaultText,
                // country:geoData.country,
                // state:geoData.state,
                // city:geoData.city,
                language: language,
                message: message,
                project: project,
                referrer: referrer,
                GCLID: gclid,
                FBCLID: fbclid,
                WBRAID: wbraid,
                TBCLID: tbclid
            }
            return newForm;
        }

    }

    async getData(task_name, options = '',ver=this._ver) {


        const res = await fetch(`${this._hostName}${this._api}${this._siteName}${ver}${this._lang}${task_name}${options}`, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded'
            },
        })

        if (!res.ok) {
            throw new Error(`Could not fetch getDate URL:${this._hostName}${this._api}${this._siteName}${ver}${this._lang}${task_name}${options} 
            STATUS: ${res.status}`)
        }

        return res.json();
    }

    async sendData(task_name, data) {
        const res = await fetch(`${this._hostName}${this._api}${this._siteName}${this._ver}${this._lang}${task_name}`,
            {
                method: "POST",
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(data)
            })

        if (!res.ok) {
            throw new Error(`Could not fetch SendData URL:${this._hostName}${this._api}${this._siteName}${this._ver}${this._lang}${task_name} 
                STATUS: ${res.status}`)
        }

        return res.json();
    }


    async getListingInformation(index = 'A10390935', ver = this._ver) {
        if (debug) {
            console.log(`Server-api-configuration...   getListingInformation try to fetch`);
        }
        //https://ngirealty.com/api/smart-luxe/v1/en/listing/A10390935
        const res = await fetch(`${this._hostName}${this._api}${this._siteName}${ver}${this._lang}listing/${index}`, {})

        if (!res.ok) {
            throw new Error(`Could not fetch ServerApi.getListingInformation: ${res.status}`)
        }
        return res.json();
    }

    async getListingAnalyseInformation(id) {
        if (debug) {
            console.log(`Server-api-configuration...   getListingAnalyseInformation try to fetch`);
        }
        //https://ngirealty.com/api/smart-luxe/v1/en/listing/A10390935
        // const res = await fetch(`${this._hostName}${this._api}${this._siteName}${this._ver}${this._lang}listing/${id}`,{})

        return (this.getData('analyse', `/${id}`))

    }

    async getFeaturedDeals() {
        return (this.getData(`featured-deals`));
    }

    async getFeaturedNeighborhoods() {
        return (this.getData(`featured-neighborhoods`));
    }

    async getNeighborhoodPageInfo(id) {
        return (this.getData(`neighborhood`, `/${id}`));
    }

    async getAboutPageInfo() {
        return (this.getData(`about`));
    }

    async getTeamPageInfo() {
        return (this.getData(`team`));
    }

    async  getDictionary() {
        return (this.getData(`dictionary`));
    }

    async sendForm(form) {
        let newForm = await this.convertToSendForm(form);
        sessionStorage.setItem('flagExitModal', 'false')
        document.dispatchEvent(new CustomEvent('SubmitForm'))
        return (this.sendData('process', newForm));
    }

    async getPrivacyInfo() {
        return (this.getData(`privacy`));
    }
    // async getGeoData(){
    //     return(this.getData(`geoip`));
    // }

    async getSimilar(id) {
        return (this.getData('similar', `/${id}`))
    }

    async search(page_size, page, data = null) {
        if (data !== null) {
            let params = '';
            for (const [key, value] of Object.entries(data)) {
                if (value !== undefined && value !== null && value !== '0' && value !== 0 && value !== '') {
                    params += `&${key}=${value}`
                }
            }
            return (this.getData(`search`, `/?page_size=${page_size}&page=${page}${params}`))
        } else {
            return (this.getData(`search`, `/?page_size=${page_size}&page=${page}`))
        }
    }

    async getSampleListings(data) {
        return (this.getData(`sample_listings`, `/?bedrooms=${data.preferBedrooms}&price_range=${data.preferPrice}`))
    }

    async getAreasForSearch() {
        return (this.getData(`areas_for_search`,))
    }

    async getSelection(id, agentId) {
        if (agentId != null) return (this.getData(`selection`, `/${id}/${agentId}`,'v2/'));
        else return (this.getData(`selection`, `/${id}`,'v2/'));

    }

    async getGeoIp() {
        return (this.getData(`geoip`))
    }

    async getProjectInfo(id) {
        return (this.getData(`project`, `/${id}`))
    }

    async getTelephoneList() {
        return (this.getData(`geoip`))
    }

    async getProjectsList(id, form = null, params = null) {
        let param = '';
        let arr = [];
        // constructionLobbySearchForm:{
        //     neighbourhood:'',
        //     condo:false,
        //     singleFamilyHome:false,
        //     townhouse:false
        // }

        // 1: condo
        // 2: condo/hotel
        // 3: townhouse
        // 4: single family
        // 5: vacation home
        // 6: office condo
        // if(form.neighbourhood!=='') arr.push(1);
        if (form != null || params != null) {
            if (form != null) {
                if (form.condo) arr.push(1);
                if (form.singleFamilyHome) arr.push(4);
                if (form.townhouse) arr.push(3);

                arr.map((item, index) => {
                    if (index === 0) param += '?'
                    param += `project_types[]=${item}`
                    if (index !== arr.length - 1) param += '&'
                })
                if (form.neighbourhood !== '') {
                    if (param != '') {
                        param += `&area_id=${form.neighbourhood}`;
                    } else {
                        param += `?area_id=${form.neighbourhood}`;
                    }
                }
                if(form.shortTermApproved){
                    if (param !='') {
                        param += `&short_term=${form.shortTermApproved}`;
                    } else {
                        param += `?short_term=${form.shortTermApproved}`;
                    }
                }
            }
            if (params != null) {
                let counter = 0;
                for (var key in params) {
                    if (counter === 0 && param == '') param += '?';
                    else param += '&';
                    counter++;

                    if (typeof item !== 'object') {
                        param += `${key}=${params[key]}`
                    }
                }

            }
            // if(form.neighbourhood!=='') param+=`project_types[]=1`
            // if(form.condo) param+=`project_types[]=2`
            // if(form.singleFamilyHome) param+=`project_types[]=3`
            // if(form.townhouse) param+=`project_types[]=4`
            return (this.getData(`projects`, `/${id}${param}`))
        } else {
            return this.getData(`projects`)
        }
    }

    async getAllArticles(page = 1) {
        return (this.getData(`articles`, `?page=${page}`))
    }

    async getArticleInfo(id) {
        return (this.getData(`article`, `/${id}`))
    }

    async getArticlesForProject(id) {
        return (this.getData(`project`, `/${id}/articles`))
    }

    async getArticlesForNeihgborhood(id) {
        return (this.getData(`area`, `/${id}/articles`))
    }

    async getAgentData(instagram) {
        return (this.getData(`agent_ig`, `/${instagram}`))
    }



    async getVersion() {
        let domain = `https://${document.location.host}`;
        if (document.location.href.includes('.test:3000')) domain = `http:/${document.location.host}` 
        else  if (document.location.href.includes('.test:8000')) domain = `https://smartluxe.com/`
       
        const res = await fetch(`${domain}/version.json?timestamp=${Date.now()}`)
        if (!res.ok) {
            throw new Error(`Could not fetch getVersion ${res.status}`)
        }
        let result = await res.json()
        return result;
    }

    
    async getCustomDictionary(name) {
        return (this.getData(`dictionary`,`/${name}`));
    }

    async getSelectionFeedback(selection_id,id,type = 'listing'){
        return (this.getData(`selection`,`/${selection_id}/${type}/${id}/feedback`))
    }

    async sendSelectionFeedback(selection_id,id,data,type = 'listing'){
        return (this.sendData(`selection/${selection_id}/${type}/${id}/feedback`,data))
    }

    async getAgreement(id){
        ///smart-luxe/v1/{locale}/agreements/sign/{id}
        return (this.getData(`agreements/sign`,`/${id}`))
    }

    async signAgreement(id,data){
        ///smart-luxe/v1/{locale}/agreements/sign/{id}
        return (this.sendData(`agreements/sign/${id}`,data))
    }

}