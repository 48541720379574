import React, { useState } from 'react';

import ProjectSlider from '../project-slider';
import ProjectSliderModal from '../project-slider-modal';
import './project-inner-page-style.css'
import HOne from "../h-one";
import UserForm from '../user-form';
import OffersGrid from '../offers-grid';
import ReactPlayer from 'react-player';
import Modal from '../modal';
import Parser from 'html-react-parser';
import ReadMore from '../read-more';
import CustomList from '../custom-list';
import ArticleGrid from '../article-grid/article-grid';
import InfoModal from '../infoModal';
import ServerApi from '../../services/server-api-service';
import StringProcessingService from '../../services/string-processing-service';
import Helmet from 'react-helmet';
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
// import { Masonry } from '@mui/lab';

import SeoService from '../../services/seo-service';
import ActivityWatcher from '../../functions/user-activity/user-activity.js'
import CookiesManager from '../../services/cookies-manager';
import ConfigurationService from '../../services/configuration-service';
import {
    BrowserRouter as Router,
    Link,
    withRouter,
} from "react-router-dom";
import { default as sold_out } from "../../elements/images/invest/sold_out.png";


const heights = [150, 30, 90, 70, 110, 150,
    130, 80, 50, 90];
  const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: "#fff",
      padding: theme.spacing(0.5),
      textAlign: "center",
      color: "purple",
  }));

class ProjectInnerPage extends React.Component {
    serverApi = new ServerApi();
    stringProcessingService = new StringProcessingService();
    cookiesManager = new CookiesManager();
    configurationService = new ConfigurationService();
    seoService = new SeoService();
    watcher = null;
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            totalPages: 12,
            isVisibleModal: false,
            isVisibleModalBottomSlider: false,
            isVisibleMessage: false,
            isVisibleMessageScrollFlag: true,
            pageLoad: false,
            currentPage: 1,
            currentSlide: 1,
            mapZoom: 15,
            observerState: false,
            currentZoomBlock: '',
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: '',
                userComment: '',
                project: ''
            },
            selectedCountry: null,
        }
    }

    _callbackParallax(target, observer) {
        let scrollTopProcent = window.scrollY / document.querySelector(target).offsetHeight * 100;
        let parallaxImg = '';
        switch (target) {
            case ".ProjectInnerАnchorBuild": {
                parallaxImg = "#ParallaxPropertyFeatures"
                break;
            }
            case ".ProjectInnerАnchorAmenties": {
                parallaxImg = "#ParallaxPropertyAmenties"
                break;
            }
            case ".ProjectInnerАnchorResidences": {
                parallaxImg = "#ParallaxPropertyResidenceFeatures"
                break;
            }
            default: ;
        }
        if (document.querySelector(parallaxImg)) document.querySelector(parallaxImg).parentElement.style.cssText = `transform: translate(0%,-${scrollTopProcent / 8}%);`

    }

    componentWillMount() {

        let threshold = [];
        for (let i = 0; i < 0.1; i += 0.005) {
            threshold.push(i);
        }
        this.setState({
            threshold: threshold
        })


        let initialData = {
            mapSide: 'rigth',
            selectionHeader: false,
            showSelectionToolBar: false,
            selectionMapFloating: true,
            leftBlockWidth: 837,
            currentPage: 'ProjectInnerPage'
        }
        this.props.saveState(initialData);
        this.watcher = new ActivityWatcher('openMessageModal');
        // this.props.saveState('mapSide','rigth');
        // this.props.saveState('selectionHeader',false);
        // this.props.saveState('showSelectionToolBar',true);
        // this.props.saveState('selectionMapFloating',true);
        // this.props.saveState('leftBlockWidth',820);
        this.getProjectInfo();
        this.getArticles();
        setTimeout(() => {
            this.setState({
                pageLoad: true
            })
        }, 5000)
        document.addEventListener('openMessageModal', this.openMessageModal)
    }
    componentWillUnmount() {
        this.watcher.stopWatcher()
        document.removeEventListener('openMessageModal', this.openMessageModal);
        if (this.props.saveCurrentSelectionListing) {
            this.props.saveCurrentSelectionListing(null)
            let initialData = {
                mapSecondaryMarkers: null,
                mapSoldOutMarkers: null,
                allowedLanguages: null,
                showSelectionToolBar: false
            }
            this.props.saveState(initialData);
        }
    }

    componentWillUpdate(prevProps) {
        // let DescBlock = document.getElementsByClassName('ProjectInnerPropDesc')[0];
        // let BuildBlock = document.getElementsByClassName('ProjectInnerАnchorBuild')[0];
        // let AmenitiesBlock = document.getElementsByClassName('ProjectInnerАnchorAmenties')[0];
        // let ResidencesBlock = document.getElementsByClassName('ProjectInnerАnchorResidences')[0];

        // let blocks = [];
        // if (DescBlock) blocks.push(DescBlock)
        // if (BuildBlock) blocks.push(BuildBlock)
        // if (AmenitiesBlock) blocks.push(AmenitiesBlock)
        // if (ResidencesBlock) blocks.push(ResidencesBlock)

        // for (let i = 0, j = 0; i < blocks.length; i++, j += 0.4) {
        //     let block = blocks[i].getBoundingClientRect();
        //     if (block.top > 0 && block.top < 500) {
        //         if (this.state.currentZoomBlock !== blocks[i].className) {
        //             this.changeMapZoom(13 + j, blocks[i].className);
        //         }
        //     }
        // }

        const buildingBlock = document.querySelector(".ProjectInnerАnchorBuild");
        if (buildingBlock) {
            let options = {
                root: buildingBlock,
                rootMargin: "0px",
                threshold: this.state.threshold,
            };

            let observer = new IntersectionObserver(this._callbackParallax.bind(this, ".ProjectInnerАnchorBuild"), options);
            observer.observe(buildingBlock)
        }


        const amentiesBlock = document.querySelector(".ProjectInnerАnchorAmenties");
        if (amentiesBlock) {
            let options = {
                root: amentiesBlock,
                rootMargin: "0px",
                threshold: this.state.threshold,
            };

            let observer = new IntersectionObserver(this._callbackParallax.bind(this, ".ProjectInnerАnchorAmenties"), options);
            observer.observe(amentiesBlock)
        }

        const residenceBlock = document.querySelector(".ProjectInnerАnchorResidences");
        if (residenceBlock) {
            let options = {
                root: residenceBlock,
                rootMargin: "0px",
                threshold: this.state.threshold,
            };

            let observer = new IntersectionObserver(this._callbackParallax.bind(this, ".ProjectInnerАnchorResidences"), options);
            observer.observe(residenceBlock)
        }



        let windowRelativeBottom = document.documentElement.getBoundingClientRect().bottom;
        let exitPop = this.cookiesManager.get('exit_pop');
        if ((windowRelativeBottom < ((document.documentElement.clientHeight + document.getElementsByClassName('Footer')[0].clientHeight) + (this.props.mobileVerison ? 1 : 0)) && this.state.pageLoad && prevProps.pageScroll > this.props.pageScroll)
            || (this.props.mouseEvent != null && this.props.mouseEvent.clientY < 5 && this.state.pageLoad)) {
            if (this.state.isVisibleMessage !== true && this.state.isVisibleMessageScrollFlag && exitPop == null) {
                this.setState({
                    isVisibleMessage: true
                })
            }

        }

        if (this.props.pageScroll != null) {
            let marksForZoom = [];
            let zoomValues = [];
            let scroll = this.props.pageScroll;
            let currentZoomMark = 1000;
            let zoom = 13;

            let scrollHeight = Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );

            while (currentZoomMark < scrollHeight) {
                marksForZoom.push(currentZoomMark);
                currentZoomMark += 500;
                zoomValues.push(zoom);
                zoom += 1
            }

            for (let i = 0; i < marksForZoom.length - 1; i++) {
                if (scroll > marksForZoom[i] && scroll < marksForZoom[i + 1]) {
                    document.dispatchEvent(new CustomEvent('changeMapZoom', { detail: { zoom: zoomValues[i] } }))
                }
            }

        }

        let innerPage = document.getElementsByClassName('ProjectInner')[0];
        if (innerPage != null) {
            if (this.props.widthRouterMark !== innerPage.clientWidth) {
                this.props.saveState({
                    widthRouterMark: innerPage.clientWidth
                })
            }
        }
    }

    openMessageModal = () => {
        let exitPop = this.cookiesManager.get('exit_pop');
        if (!this.state.isVisibleMessage && this.state.isVisibleMessageScrollFlag && exitPop == null) {
            this.setState({
                isVisibleMessage: true
            })
        }
    }

    getArticles() {
        if (this.props.match.params.id) {
            this.serverApi.getArticlesForProject(this.props.match.params.id)
                .then((data) => {
                    let articles = data.data;
                    articles.sort((article1, article2) => { return new Date(article2.date) - new Date(article1.date) })
                    this.setState({
                        articles: articles
                    })

                })
                .catch((error) => {
                    console.log(`ERROR: fetch getProjectInfo ... -> Component: ProjectInnerPage ERROR:${error}`);
                })
        }
    }

    getProjectInfo() {
        if (this.props.match.params.id) {
            this.serverApi.getProjectInfo(this.props.match.params.id)
                .then((data) => {
                    if (this.state.debug) console.log(`getProjectInfo data:`, data);
                    let projectName = '';
                    if (this.configurationService.getCurrentLanguagePrefix() != 'tr') projectName = data.name

                    let parallaxResidence = data.gallery.find((element) => element.residence_illustration == 1);
                    let parallaxAmenties = data.gallery.find((element) => element.amenities_illustration == 1);
                    let parallaxBuilding = data.gallery.find((element) => element.building_illustration == 1);
                    let highlightsIllustration = data.gallery.find((element) => element.highlights_illustration == 1);
                    // console.log("parallaxResidence :",parallaxResidence)
                    // console.log("parallaxAmenties :",parallaxAmenties)
                    // console.log("parallaxBuilding :",parallaxBuilding)
                    // console.log("highlightsIllustration :",highlightsIllustration)

                    let parallaxImages = {}
                    // if(parallaxResidence) parallaxImages.parallaxResidence = `https://ngirealty.com/img/projects/${data.id}/features/${parallaxResidence.residence_illustration_image}`
                    if (parallaxAmenties && parallaxAmenties.amenities_illustration_image) parallaxImages.parallaxAmenties = `https://ngirealty.com/img/projects/${data.id}/features/${parallaxAmenties.amenities_illustration_image}`
                    // if(parallaxBuilding) parallaxImages.parallaxBuilding =`https://ngirealty.com/img/projects/${data.id}/features/${parallaxBuilding.building_illustration_image}` 
                    if (highlightsIllustration) parallaxImages.highlightsIllustration = `https://ngirealty.com/img/projects/${data.id}/features/${highlightsIllustration.highlights_illustration_image}`

                    this.setState({
                        ...parallaxImages,
                        data: data,
                        // parallaxResidence:`https://ngirealty.com/img/projects/${data.id}/features/${parallaxResidence.residence_illustration_image}`,
                        // parallaxAmenties:`https://ngirealty.com/img/projects/${data.id}/features/${parallaxAmenties.amenities_illustration_image}`,
                        // parallaxBuilding:`https://ngirealty.com/img/projects/${data.id}/features/${parallaxBuilding.building_illustration_image}`,
                        // highlightsIllustration:`https://ngirealty.com/img/projects/${data.id}/features/${highlightsIllustration.highlights_illustration_image}`,
                        contactForm: {
                            ...this.contactForm,
                            userComment: `${this.props.dictionary.send_me_more_details_about_project} ${projectName}`,
                            project: data.name
                        },

                    })


                    this.props.saveCurrentSelectionListing(data)
                    this.props.saveDataForMap([
                        {
                            latitude: parseFloat(data.latitude),
                            longitude: parseFloat(data.longitude),
                            id: data.id,
                            logo: data.logo,
                            img: `https://ngirealty.com/img/projects/${data.id}/${data.logo}`,
                            max_price: data.max_price,
                            min_price: data.min_price,
                            completion: data.completion,
                            units_total: data.units_total,
                            units_sold: data.units_sold,
                            timeline: data.timeline
                        }
                    ]);

                    let initialData = {
                        allowedLanguages: data.ready_languages,
                        mapSecondaryMarkers: data.area_projects,
                        mapSoldOutMarkers: data.sold_projects
                    }
                    this.props.saveState(initialData);
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getProjectInfo ... -> Component: ProjectInnerPage ERROR:${error}`);
                    this.props.history.replace({ pathname: `/page-not-found` });
                })
        }
    }

    handleInput = (event) => {
        this.setState({
            contactForm: {
                ...this.state.contactForm,
                [event.target.name]: event.target.value
            }
        })
    }

    closeModal = (isVisibleMessageScrollFlag = true) => {
        this.setState({
            isVisibleModal: false,
            isVisibleMessageScrollFlag: isVisibleMessageScrollFlag,
            isVisibleMessage: false,
            isVisibleModalBottomSlider: false
        })
    }

    switchToPage = (page) => {

        this.setState({
            currentPage: page,
            // data: undefined
        })
    }


    saveCurrentSelectedCountry = (value) => {
        this.setState({
            selectedCountry: value
        })
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: '',
                userComment: ''
            }
        })
    }

    changeMapZoom = (zoom, blockName) => {
        this.setState((prevState) => {
            if (prevState.currentZoomBlock !== blockName) {
                document.dispatchEvent(new CustomEvent('changeMapZoom', { detail: { zoom: zoom } }))
                return {
                    currentZoomBlock: blockName,
                }
            }
        })

    }




    renderPagination() {
        // let { dictionary } = this.props;
        let { totalPages, currentPage, isVisibleModal } = this.state;
        if (this.state.totalPages !== undefined) {
            const result = [];
            for (let i = 1; i < totalPages; i++) {
                if (totalPages > 10) {
                    if (currentPage < 3) {
                        if (i < 4) {
                            result.push(<div className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                            if (i === 3) {
                                result.push(<div className="PaginationDots">...</div>)
                            }
                        } else if (i > totalPages - 4) {
                            result.push(<div className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    } else if (currentPage > totalPages - 3) {
                        if (i < 4) {
                            result.push(<div className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                            if (i === 3) {
                                result.push(<div className="PaginationDots">...</div>)
                            }
                        } else if (i > totalPages - 4) {
                            result.push(<div className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    } else {
                        if (i > currentPage - 4 && i < currentPage + 4) {
                            result.push(<div className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    }

                } else {
                    result.push(<div className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                }
            }
            return (<div className="PaginationDiv">{result}</div>)
        }
    }

    renderForm(logo = false, newButtons = true, topForm = false) {

        const iconLock = require('../../elements/icons/s_secured.png');

        let { width, dictionary, country } = this.props;
        let { contactForm, selectedCountry, data } = this.state;


        let contactUrgencyState = contactForm
        let urgencyMessage = null, disableMessage = false;

        if (data.urgency && topForm) {
            urgencyMessage = data.urgency;
        }

        if (topForm) disableMessage = true

        const formTitle = () => {

            let title;
            let image;

            if (logo) {
                title = <HOne addclass={"xsmall"} align={width > 1000 ? ("left") : ("center")} title={`${dictionary.request_about}`} />
                if (data.logo) {
                    image = <img src={data.logo === null && !logo ? null : `https://ngirealty.com/img/projects/${data.id}/${data.logo}`} className={"ProjectLogo"} alt=""></img>
                }
            } else
                if (topForm && data.urgency) {
                    title = <HOne addclass={"xsmall"} align={width > 1000 ? ("left") : ("center")} title={`${data.urgency ? (data.urgency.toUpperCase()) : ('')}`} />
                } else {
                    let projectName = '';
                    title = <HOne addclass={"xsmall"} align={width > 1000 ? ("left") : ("center")} title={`${dictionary.request_about}`} />
                }

            return (
                <>
                    {title}
                    {image}
                </>
            )
        }

        return (
            <div className={"ProjectInnerForm ProjectInnerBox"}>
                <div className={"ProjectInnerFormDesc"}>
                    {/* <HOne addclass={"xsmall"} align={width > 1000 ? ("left") : ("center")} title={`${dictionary.request_about} ${data.name}`} />
                    <img src={data.logo === null&&!logo? null : `https://ngirealty.com/img/projects/${data.id}/${data.logo}`} className={"ProjectLogo"} alt=""></img> */}
                    {formTitle()}

                    {/* {data.urgency && <div className={"FeelFree"}>{data.urgency}</div>} */}

                    {/* {width > 1000 && data.urgency ? (<div className={"sep"}></div>) : (null)} */}

                    {width > 1000 ? (
                        <div className="SecureDiv">
                            <img className="SecureIcon" src={iconLock} alt="" /><div className="Text">{dictionary.information_secure}</div>
                        </div>
                    ) : (null)}
                </div>
                <div className={"Text"}>
                    <UserForm
                        disableMessage={disableMessage}
                        watcher={this.watcher}
                        newButtons={newButtons}
                        message={dictionary.thank_you_deal}
                        urgencyMessage={urgencyMessage}
                        thankModal={true}
                        clearForm={this.clearForm}
                        country={country}
                        secureInfo={width < 1000 ? (true) : (false)}
                        contactForm={contactUrgencyState}
                        handleInput={this.handleInput}
                        selectedCountry={selectedCountry}
                        saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                        width={width}
                        className={"InnerForm"}
                        dictionary={dictionary}
                    />
                </div>
            </div>
        )
    }

    renderPropDesc() {
        let { width, dictionary } = this.props;
        let { data } = this.state;
        let firstPart, secondPart;
        if (data !== undefined && data.web_description) {
            let par = Parser(data.web_description);

            if (width > 1000 || !Array.isArray(par)) {
                return (
                    <div className={"PropDesc"}>
                        {Parser(data.web_description)}
                    </div>
                )
            } else {
                firstPart = par[0];
                par.shift();
                secondPart = par;
                return (
                    <div className={"PropDesc"}>
                        {firstPart}

                        <ReadMore
                            dictionary={dictionary}
                        >
                            {secondPart}
                        </ReadMore>
                    </div>
                )
            }
        } else return null;
    }

    renderTimeLine() {
        const step = require('../../elements/images/step.svg').default;
        const step_dis = require('../../elements/images/step_dis.svg').default;
        const steps_sep = require('../../elements/images/steps_sep.svg').default;
        const steps_sep_dis = require('../../elements/images/steps_sep_dis.svg').default;
        const step_point_dis = require('../../elements/images/step_point_dis.svg').default;
        const step_point = require('../../elements/images/step_point.svg').default;

        let { data } = this.state;
        let { width } = this.props;

        if (data !== undefined && data.timeline !== undefined) {

            let steps = [];
            let stepCounts = 15;


            //CHOOSE POINT COUNTS
            if (width < 1000) stepCounts = 11;
            if (width < 800) stepCounts = 8;
            if (width < 700) stepCounts = 4;
            // if(width<400) stepCounts=3;

            const renderPoints = (image) => {
                let res = [];
                for (let i = 0; i < stepCounts; i++) {
                    res.push(<img className="StepPoint" src={image} alt="" />);
                }
                return res;
            }

            for (let i = 0; i <= 4; i++) {
                if(i == 1 || i == 3) continue;
                if (i <= data.timeline) {
                    if (i !== 0) {
                        steps.push(renderPoints(step_point));
                    }
                    steps.push(<img className={'Step'} src={step} alt="" />);
                    //steps.push( <img src={steps_sep} alt="" />);

                } else {
                    steps.push(renderPoints(step_point_dis));
                    steps.push(<img src={step_dis} alt="" />);
                }
            }
            return (

                <div className={"BuildingLine"}>
                    {/* <img src={step} alt="" />
                <img src={steps_sep} alt="" />
                <img src={step} alt="" />
                <img src={steps_sep} alt="" />
                <img src={step} alt="" />
                <img src={steps_sep} alt="" />
                <img src={step} alt="" />
                <img src={steps_sep_dis} alt="" />
                <img src={step_dis} alt="" /> */}

                    {steps}
                </div>
            )
        } else return null;

    }

    renderProjectInnerBlock(title, data) {//deprecated

        let { dictionary } = this.props;
        if (data) {
            let parsedData = Parser(data);
            let arrayOfData = [];
            console.log(parsedData)
            for (let i = 0; i < parsedData.props.children.length; i++) {
                if (parsedData.props.children[i] !== "\n\t") {
                    if (parsedData.props.children[i].props) {
                        arrayOfData.push(parsedData.props.children[i].props.children);
                    }

                }
            }

            const renderBlock = (str) => {
                return (
                    <div className={"FeatureBlock"}>
                        <div className={"FeatureBul"}><div className={"Bul"}></div></div>
                        <div className={"Feature"}>{str}</div>
                    </div>
                )
            }
            return (
                <div className={"ProjectInnerFeature ProjectInnerBox"}>
                    <HOne addclass={"xsmall"} align={"left"} title={title} />
                    <div className={"ProjectInnerFeaturesBlock"}>
                        <div className={"FeaturesBlock FeaturesBlockLt"}>
                            <div className={"FeatureBlock"}>
                                <div className={"FeatureBul"}><div className={"Bul"}></div></div>
                                <div className={"Feature"}>249 residences ranging in size from 776 to 3,788 square feet</div>
                            </div>
                            <div className={"FeatureBlock"}>
                                <div className={"FeatureBul"}><div className={"Bul"}></div></div>
                                <div className={"Feature"}>57 stories</div>
                            </div>
                            <div className={"FeatureBlock"}>
                                <div className={"FeatureBul"}><div className={"Bul"}></div></div>
                                <div className={"Feature"}>200 linear feet of bayfront frontage</div>
                            </div>
                        </div>
                        <div className={"FeaturesBlock"}>
                            <div className={"FeatureBlock FeaturesBlockLt"}>
                                <div className={"FeatureBul"}><div className={"Bul"}></div></div>
                                <div className={"Feature"}>Minimalist modern design and architecture to maximize natural light</div>
                            </div>
                            <div className={"FeatureBlock"}>
                                <div className={"FeatureBul"}><div className={"Bul"}></div></div>
                                <div className={"Feature"}>Exterior sculptural columns wrapped in aluminum, framing the building</div>
                            </div>
                        </div>
                    </div>
                    <ReadMore
                        dictionary={dictionary}>
                    </ReadMore>
                </div>

            )
        } else return null;

    }

    renderDescItem(title, value) {
        let { width, dictionary, country } = this.props;
        let { data, contactForm } = this.state;

        if (title !== undefined && value !== undefined && value !== null && title !== null) {
            return (
                <div className={"DescPart"}>
                    <div className={"Hdr"}>{title} {(title == dictionary.price_range || title == dictionary.starting_price) ? (
                        <InfoModal
                            dictionary={dictionary}
                            starIcon={true}
                        >
                            <UserForm
                                watcher={this.watcher}
                                className="MobileMessageModal"
                                thankModal={true}
                                inputBans={{ comment: true, message: true }}
                                clearForm={this.clearForm}
                                message={dictionary.thank_you_deal}
                                initialMessage={`${dictionary.send_me_more_details_about_project} ${data.name}`}
                                saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                                country={country}
                                topPartRow={true}
                                contactForm={contactForm}
                                handleInput={this.handleInput}
                                width={width}
                                secureInfo={true}
                                dictionary={dictionary}
                                style={{ textAlign: 'center', justifyContent: 'center' }}
                            />
                            <div className='CoverBlock'>
                                <img src={`https://ngirealty.com/img/projects/${data.id}/${data.cover_image}`} alt='cover' className='CoverImage' />
                            </div>
                        </InfoModal>
                    ) : (null)}</div>
                    {value}

                </div>
            )
        }
        return null;
    }

    renderShortTermIcon() {
        let { data } = this.state;
        const shortTermIcon = require(`../../elements/images/seal_short_term_${this.configurationService.getCurrentLanguagePrefix()}.png`)
        if (data.short_rental == 'daily') {
            return <img className='ShortTermIcon' src={shortTermIcon} alt='Short term icon' />
        }
        return null;
    }

    renderBottomGallery() {
        let { data, isVisibleModalBottomSlider, currentSlide } = this.state;
        let { width, dictionary, country } = this.props;
        if (data.gallery) {
            if (width > 750) {
                let imagesForRender = data.gallery.slice(0, data.gallery.length > 12 ? 12 : data.gallery.length);
                return (
                    <>
                        {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} >
                            <Masonry columns={{ xs: 1, sm: 1}} gutter="10px">
                                {imagesForRender.map((item, index) => {
                                    return <img
                                        onClick={()=>{
                                            this.setState({
                                                currentSlide:parseInt(item.order),
                                                isVisibleModalBottomSlider:true
                                            })
                                        }}
                                        key={`${data.id}_${index}`}
                                        className='Photo'
                                        style={{ width: "100%", display: "block" }}
                                        src={`https://ngirealty.com/img/projects/${data.id}/thumb/${item.photo}`}
                                        alt={`Photo: ${index}`} />
                                })}
                            </Masonry>
                        </ResponsiveMasonry> */}

<Box
                sx={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "50%",
                }}
            >
                <Masonry style={{width:'100%',alignContent:'center'}} columns={3} spacing={0}>
                {imagesForRender.map((item, index) => {
                                    return <img
                                        onClick={()=>{
                                            this.setState({
                                                currentSlide:parseInt(item.order),
                                                isVisibleModalBottomSlider:true
                                            })
                                        }}
                                        key={`${data.id}_${index}`}
                                        className='Photo'
                                        style={{ width: "30%", display: "block" }}
                                        src={`https://ngirealty.com/img/projects/${data.id}/thumb/${item.photo}`}
                                        alt={`Photo: ${index}`} />
                                })}
                </Masonry>
            </Box>

                        <Modal
                            ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                            ModalStyle={{ height: "100%", boxShadow: 'none', backgroundColor: 'unset', width: "100%", justifyContent: 'center', flexDirection: 'column' }}
                            closeModal={this.closeModal}
                            closeColor={'#fff'}
                            isVisible={isVisibleModalBottomSlider}
                        >
                            <ProjectSliderModal
                                projectData={data}
                                data={data.gallery}
                                width={width}
                                dictionary={dictionary}
                                closeModal={this.closeModal}
                                currentSlide={currentSlide}
                                country={country}
                            />

                        </Modal>
                    </>
                )
            } else {
                return (
                    <div className={"slider"}>
                        {this.renderShortTermIcon()}
                        <ProjectSlider
                            projectData={data}
                            data={data.gallery}
                            width={width}
                            dictionary={dictionary}
                            country={country}
                        />
                    </div>
                )
            }

        }
        return null;
    }

    renderMediaBlock() {
        let { width, dictionary, country, neighbourhoods } = this.props;
        let { data, highlightsIllustration } = this.state;

        if (data.video) {
            return (
                <div className={"ProjectInnerVideo ProjectInnerBox"}>
                    {/* <img src={tmp_video} alt="" /> */}
                    <ReactPlayer
                        className='react-player fixed-bottom'
                        url={data.video}
                        width='100%'
                        height='100%'
                        controls={true}
                        config={{
                            youtube: {
                                playerVars: {
                                    modestbranding: 1,
                                    color: 'red'
                                }
                            },

                        }}
                        onPlay={() => {
                            this.watcher.stopWatcher();
                        }}
                        onPause={() => {
                            this.watcher.resumeWatcher();
                        }}
                        onReady={() => {
                            // if (!this.state.videoIsReady) {
                            //      setInterval(async () => {
                            //         let { wordFlag, wordCounter } = this.state;
                            //         if (this.state.wordCounter === this.props.dictionary.slogan_part_1.split(' ').length && !wordFlag) {
                            //             await this.sleep(1000);
                            //         }
                            //         if (!wordFlag) {
                            //             this.setState((prevState) => {
                            //                 return {
                            //                     wordCounter: ++prevState.wordCounter
                            //                 }
                            //             })
                            //         } else if (wordFlag) {
                            //             this.setState((prevState) => {
                            //                 return {
                            //                     wordCounter: --prevState.wordCounter
                            //                 }
                            //             })
                            //         }
                            //         if (wordCounter >= 5) setTimeout(() => { this.setState({ wordFlag: true }) }, 2000)
                            //         else if (wordCounter === 0) setTimeout(() => { this.setState({ wordFlag: false }) }, 2000)
                            //     }, 500)
                            // }
                            // this.setState({ videoIsReady: true })

                        }}
                    />
                    {/* video */}
                </div>
            )
        } else if (highlightsIllustration) {
            return (<img src={highlightsIllustration} className='HighlightsIllustration' alt='highlightsIllustration' />)
        }
        return null;
    }

    render() {

        // const tempMissoni = require('../../elements/images/missoni.png');

        const info = require('../../elements/images/i.svg').default;
        const infoDark = require('../../elements/images/i_dark.svg').default;
        // const arr_down = require('../../elements/images/arr_down.svg');
        // const tmp_video = require('../../elements/images/tmp_video.jpg');
        // const iconDate = require('../../elements/images/icon_date.svg');
        // const artImgTmp = require('../../elements/images/atr_img_tmp.jpg');
        // const iconLock = require('../../elements/icons/s_secured.png');
        const sold_out = require('../../elements/images/invest/sold_out_transp_w.png');

        let { width, dictionary, country, neighbourhoods } = this.props;
        let { data, contactForm, offers, isVisibleModal, currentZoom, articles, isVisibleMessage, parallaxResidence, parallaxAmenties, parallaxBuilding } = this.state;

        let neighbourhood = { id: null, name: null };
        let QValue = '';

        const getPrice = () => {
            if (data.min_price && data.max_price) {
                return ('$' + this.stringProcessingService.convertToFormat(data.min_price.toString()) + ' - $' + this.stringProcessingService.convertToFormat(data.max_price.toString()))
            } else if (data.min_price) {
                return ('$' + this.stringProcessingService.convertToFormat(data.min_price.toString()))
            } else return '';
        }

        if (data !== undefined) {

            // let parallaxResidence = `https://ngirealty.com/img/projects/${data.id}/thumb/${data.gallery[1].photo}`;
            // let parallaxAmenties = `https://ngirealty.com/img/projects/${data.id}/thumb/${data.gallery[1].photo}`;
            // let parallaxBuilding = `https://ngirealty.com/img/projects/${data.id}/thumb/${data.gallery[1].photo}`;




            let month = '';
            if (data.completion) {
                month = parseInt(data.completion.slice(5, 7));
                if ((month >= 1 && month <= 2) || month == 12) QValue = dictionary.winter
                else if (month >= 3 && month <= 5) QValue = dictionary.spring
                else if (month >= 6 && month <= 8) QValue = dictionary.summer
                else if (month >= 9 && month <= 11) QValue = dictionary.fall

            }
            if (neighbourhoods) {
                for (let i = 0; i < neighbourhoods.length; i++) {
                    if (data.area_id === neighbourhoods[i].id) {
                        neighbourhood.id = neighbourhoods[i].id;
                        neighbourhood.name = neighbourhoods[i].name;
                    };
                }
            }

            return (
                <div className={"Page ProjectInner"}>
                    <Helmet>
                        <title> {data.name} | Smart Luxe</title>
                        <link rel="canonical" href={`https://smartluxe.com/projects/${this.props.match.params.id}/${this.props.match.params.name}`}></link>
                        {this.seoService.insertHrefLangTags(
                            {
                                path: `/projects/:id/:name`,
                                params: {
                                    id: this.props.match.params.id,
                                    name: this.props.match.params.name
                                }
                            })
                        }
                    </Helmet>
                    <div className={"slider"}>
                        {this.renderShortTermIcon()}
                        <ProjectSlider
                            projectData={data}
                            data={data.gallery}
                            width={width}
                            dictionary={dictionary}
                            country={country}
                        />
                    </div>
                    <div className={"ProjectInnerHdr ProjectInnerBox"}>
                        {data.sold_out && <img src={sold_out} className={"soldOut"} alt="" />}
                        <div className={"NameAndAddress"}>
                            <h1 className={"ProjectInnerName"}>{data.name}</h1>
                            <div className={"ProjectInnerAddress"}>{data.web_location}</div>
                        </div>
                        {width < 1000 ? (
                            <div className={'Sep'}></div>
                        ) : (this.renderShortTermIcon())}
                        <div className={"ProjectInnerLogo"}>

                            <img src={`https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`} alt="" className={"ProjectLogo"} />
                        </div>
                    </div>
                    <div className={"ProjectInnerBox"}>


                        <div className={"BuildingSteps"}>

                            {this.renderTimeLine()}
                            <div className={"BuildingMarks"}>
                                <div style={{flex:2}}>{dictionary.reservation}</div>
                                {/* <div>{dictionary.contract}</div> */}
                                <div style={{flex:4}}>{dictionary.ground_breaking}</div>
                                {/* <div>{dictionary.topoff}</div> */}
                                <div style={{flex:2}}>{dictionary.completed}</div>
                            </div>

                            <img className={"infoIcon"} src={info}
                                onMouseOver={e => {
                                    e.target.src = infoDark;
                                }}

                                onMouseOut={e => {
                                    e.target.src = info;
                                }}

                                onClick={() => {
                                    this.setState((prevState) => {
                                        return {
                                            isVisibleModal: !prevState.isVisibleModal
                                        }
                                    })
                                }}
                            />


                        </div>
                        {/* <div className={"est"}>{dictionary.est}: {QValue} {data.completion&&data.completion.slice(0,4)}</div> */}
                    </div>
                    {this.renderForm(false, true, true)}

                    <div className={"ProjectInnerPropDesc ProjectInnerBox"}>



                        <HOne addclass={"xsmall"} align={"left"} title={dictionary.property_description} />
                        <div className={"PropDescBlock"} id={"PropDescBlock"}>
                            {this.renderPropDesc()}
                            {/* <div className={"PropDesc"}>
                                <p>Boasting an impressive 200 feet of frontage on Biscayne Bay in East Edgewater, Missoni Baia is a one-of-a-kind work of high-performance architecture. Designed by internationally acclaimed Hani Rashid of Asymptote Architecture and developed by OKO Group under the leadership of Vladislav Doronin, one of Europe’s most successful developers, this visionary architectural landmark is influenced by Minimalist art and driven by forward-thinking engineering.</p>
    
                                <p>Missoni Baia offers a residential experience of unparalleled quality, as distinctive as the aesthetic of Missoni, one of the world’s most legendary fashion houses, and inspired by the same pioneering spirit of technical innovation and design excellence. New York designer Paris Forino envisioned the tower’s 249 elegantly crafted one to five bedroom residences and curated the Missoni fabrics, furnishings, and artworks that complement Asymptote’s modern architectural design.</p>
    
                                <p>The building offers expansive amenities unrivaled in Miami—including an Olympic-sized pool and one of the city’s largest and most elegantly appointed spas—and a richly layered landscape designed by Enzo Enea. Missoni Baia sets a new standard for quality and sophistication in East Edgewater.</p>
    
                                <ReadMore>
                                    12312321
                                </ReadMore>                    
                            </div> */}
                            {/*{width > 1000?(*/}
                            <div className={"PropDescRt"}>
                                {/* <div className={"DescPart"}>
                                        <div className={"Hdr"}>Architect</div>
                                        {data.architect}
                                    </div> */}
                                {this.renderDescItem(dictionary.architect, data.architect)}

                                {
                                    neighbourhood.name !== null && neighbourhood.id !== null ?
                                        (
                                            <div className={"DescPart"}>
                                                <div className={"Hdr"}>{dictionary.neighbourhood}</div>
                                                <Link to={`/neighborhood/${neighbourhood.id}/${this.stringProcessingService.StringFormatUrl(neighbourhood.name)}`}>{neighbourhood.name}</Link>
                                            </div>
                                        ) : null
                                }

                                {this.renderDescItem(dictionary.developer, data.developer)}
                                {this.renderDescItem(dictionary.interior_designer, data.designer)}
                                {this.renderDescItem(data.min_price && data.max_price ? (dictionary.price_range) : (dictionary.starting_price), getPrice())}
                                {this.renderDescItem(dictionary.units, data.units_total)}
                                {this.renderDescItem(dictionary.floors, data.floors)}
                                {this.renderDescItem(`${dictionary.occupancy_year}`, data.completion ? data.completion.slice(0, 4) + (new Date(data.completion) > Date.now() ? (` (${dictionary.est})`) : ('')) : null)}
                                {/* {this.renderDescItem(dictionary.occupancy_year, data.completed ? data.completed.slice(0, 4) : null)} */}

                                {/* {this.renderDescItem(dictionary.Occupancy, data.completion ? data.completion.slice(0, 4) : null)} */}

                            </div>
                            {/*):(null)}*/}
                        </div>
                    </div>


                    {this.renderMediaBlock()}

                    {/* {this.renderProjectInnerBlock("BUILDING FEATURES",data.web_building_features)} */}

                    {data.web_building_features && (
                        <div className={"ProjectInnerFeature ProjectInnerBox ProjectInnerАnchorBuild"} key={'ProjectInnerАnchorBuild'}>
                            {parallaxBuilding && <div className='ParallaxItem'> <img id='ParallaxPropertyFeatures' src={parallaxBuilding} alt="Parallax" /> </div>}
                            <div className={parallaxBuilding ? "ParallaxContent" : "ParallaxDisabled"}>
                                <HOne addclass={"xsmall"} align={"left"} title={dictionary.building_features} />
                                <CustomList
                                    width={width}
                                    elem={data.web_building_features}
                                    listName={dictionary.building_features}
                                    dictionary={dictionary}

                                />
                            </div>
                        </div>

                    )}



                    {data.web_amenities && (
                        <div className={"ProjectInnerFeature ProjectInnerBox ProjectInnerАnchorAmenties"} key={'ProjectInnerАnchorAmenties'} >
                            {parallaxAmenties && <div className='ParallaxItem'> <img id='ParallaxPropertyAmenties' src={parallaxAmenties} alt="Parallax" /> </div>}
                            <div className={parallaxAmenties ? "ParallaxContent" : "ParallaxDisabled"}>

                                <HOne addclass={"xsmall"} align={"left"} title={dictionary.amenities} />
                                <CustomList
                                    width={width}
                                    elem={data.web_amenities}
                                    listName={dictionary.amenities}
                                    dictionary={dictionary}
                                // style={{columnCount:2}}
                                />
                            </div>
                        </div>
                    )}

                    {data.web_residences && (
                        <div className={"ProjectInnerFeature ProjectInnerBox ProjectInnerАnchorResidences"} key={'ProjectInnerАnchorResidences'}>
                            {parallaxResidence && <div className='ParallaxItem'> <img id='ParallaxPropertyResidenceFeatures' src={parallaxResidence} alt="Parallax" /> </div>}
                            <div className={parallaxResidence ? "ParallaxContent" : "ParallaxDisabled"}>
                                <HOne addclass={"xsmall"} align={"left"} title={dictionary.residence_features} />
                                <CustomList
                                    width={width}
                                    listName={dictionary.residence_features}
                                    elem={data.web_residences}
                                    dictionary={dictionary}
                                // style={{columnCount:2}}
                                />
                            </div>
                        </div>
                    )}


                    {offers &&
                        <div className={"ProjectInnerResaleListing ProjectInnerBox ProjectInnerАnchorResales"}>
                            <HOne addclass={"xsmall"} align={"left"} title={dictionary.resales} />
                            <br />
                            <OffersGrid
                                data={offers}
                                wizardOffers={true}
                                dealScore={false}
                                verticalList={true}
                                dictionary={dictionary}
                                width={1200}
                                limit={5}
                            />
                            {this.renderPagination()}
                        </div>}

                    {
                        articles != null && Array.isArray(articles) && articles.length > 0 ? (
                            <ArticleGrid
                                project={{ name: data.name, id: data.id }}
                                data={articles}
                                dictionary={dictionary}
                            />) : (null)
                    }

                    {this.renderBottomGallery()}

                    {this.renderForm(true)}

                    {/* <div className={"ProjectInnerForm ProjectInnerBox"}>
                        <div className={"ProjectInnerFormDesc"}>
                            <HOne className={''} addclass={"xsmall"} align={"left"} title="REQUEST MORE INFORMATION ABOUT MISSONI BAIA" />
                            <div className={"FeelFree"}>Feel free to contact us for more details regarding this project</div>
                            {width>1000?(<div className={"sep"}></div>):(null)}
                            <div className="SecureDiv">
                                <img className="SecureIcon" src={iconLock} alt="" /><div className="Text">Your information is 100% confidential and won't be shared.</div>
                            </div>
                        </div>
                        <div className  ={"Text"}>
                            <UserForm
                                country={country}
                                contactForm={contactForm}
                                handleInput={this.handleInput}
                                width={width}
                                dictionary={dictionary}
                                className={"InnerForm"}
                            />
                        </div>
                    </div> */}

                    <Modal
                        ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                        closeModal={this.closeModal.bind(this, false)}
                        modalClass={'MessageModal'}
                        closeColor={'#000'}
                        speed={0.5}
                        ModalStyle={{ justifyContent: 'center', flexDirection: 'column', padding: "20px", alignItems: 'center' }}
                        isVisible={isVisibleMessage}>
                        <HOne addclass={"xsmall"} align={"center"} title={`${dictionary.request_about}`} line={"none"} />
                        <img src={data.logo === null ? null : `https://ngirealty.com/img/projects/${data.id}/${data.logo}`} className={"ProjectLogo"} alt=""></img>
                        <br />
                        {data.urgency ?
                            <React.Fragment>
                                <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#a96903" />
                                            <stop offset="0.258" stopColor="#aa6a02" />
                                            <stop offset="0.352" stopColor="#b07102" />
                                            <stop offset="0.418" stopColor="#b97d02" />
                                            <stop offset="0.472" stopColor="#c78e01" />
                                            <stop offset="0.518" stopColor="#daa401" />
                                            <stop offset="0.557" stopColor="#f0be00" />
                                            <stop offset="0.579" stopColor="#ffd100" />
                                            <stop offset="1" stopColor="#a96903" />
                                        </linearGradient>
                                    </defs>

                                    <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-2822.173 274.933)">
                                        <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                    </g>
                                </svg>
                                <br />
                                <div className={"FormText"}>
                                    {data.urgency}
                                </div>
                            </React.Fragment>
                            : (null)
                        }
                        <UserForm
                            watcher={this.watcher}
                            className="MobileMessageModal"
                            thankModal={true}
                            inputBans={{ comment: true, message: true }}
                            clearForm={this.clearForm}
                            message={dictionary.thank_you_deal}
                            initialMessage={`${dictionary.send_me_more_details_about_project} ${data.name}`}
                            saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                            country={country}
                            topPartRow={true}
                            contactForm={contactForm}
                            handleInput={this.handleInput}
                            width={width}
                            secureInfo={true}
                            dictionary={dictionary}
                            style={{ textAlign: 'center', justifyContent: 'center' }}
                        />
                        <div className='CoverBlock'>
                            <img src={`https://ngirealty.com/img/projects/${data.id}/${data.cover_image}`} alt='cover' className='CoverImage' />
                        </div>
                    </Modal>
                    <Modal
                        isVisible={isVisibleModal}
                        closeModal={this.closeModal}
                    >
                        <div className="InfoModal">
                            <div className={"est"}>{data.timeline === 4 ? (dictionary.completed) : (dictionary.estimated_delivery)}: {QValue} {data.completion && parseInt(data.completion.slice(0, 4)) + ((QValue === dictionary.winter && month === 12) && 1)}</div>

                        </div>
                        <div onClick={this.closeModal}>
                            <svg className="CloseIcon" xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
                                <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
                                    <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                    <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                </g>
                            </svg>

                        </div>
                    </Modal>
                </div>

            )
        }
        return null;
    };
}

export default withRouter(ProjectInnerPage);